import styled from "@xstyled/styled-components";
import { motion } from "framer-motion";
import { CSSProperties } from "react";

import { ApplicationProcess } from "../components/ApplicationProcess";
import { CompanyBenefits } from "../components/CompanyBenefits";
import { EmployeeEndorsements } from "../components/EmployeeEndorsements";
import { JobInvolves } from "../components/JobInvolves";
import { JobRequirements } from "../components/JobRequirements";
import { SalarySubmission } from "../components/SalarySubmission";
import { VisaDisclaimer } from "../components/VisaDisclaimer";
import {
  CompanyOtherJobsButton,
  CompanyOtherJobsSection,
} from "../components/CompanyOtherJobs";
import { CompanyLocation } from "../components/CompanyLocation";
import { HideCompanyLink } from "../components/HideCompanyLink";
import { JobCardSection } from "../components/JobCardSection";
import { ReportAProblem } from "../components/ReportAProblem";
import { ShareLink } from "../components/ShareLink";
import SalaryBenchmarks from "../components/SalaryBenchmarks";
import { MeetTheTeamSection } from "../components/MeetTheTeamSection";
import { JobCardContext } from "../JobHeader";

import { SeparateContent } from "./DesktopContent";
import { useTrackEBContent } from "./JobContent";

import { CompanyFunding } from "@otta/search/components/CompanyFunding";
import { OttasTake } from "@otta/search/components/OttasTake";
import {
  Insights,
  shouldDisplayInsights,
} from "@otta/search/components/Insights";
import { CompanyAdditionalInfo } from "@otta/search/components/CompanyAdditionalInfo";
import { CompanyValues } from "@otta/search/components/CompanyValues";
import { CompanyArticles } from "@otta/search/components/CompanyArticles";
import { PeopleAtCompany } from "@otta/search/components/PeopleAtCompany";
import { palette, pxToRem } from "@otta/design-tokens";
import { WttjSymbol } from "@otta/design";
import { DiversityInitiatives } from "@otta/search/components/DiversityInitiatives";
import { useHasEBContent } from "@otta/search/hooks/useHasEBContent";
import { PeopleBreakdown } from "@otta/search/components/PeopleAtCompany/PeopleBreakdown";
import { CompanyWorkflowStatus } from "@otta/search/schema";

const StyledWttjSymbol = styled(WttjSymbol)`
  height: ${pxToRem(32)};
`;

const MainContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: xl;
  max-width: 1024px;
`;

const ActionButtonContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: lg;
`;

const CompanyContainer = styled.div`
  max-width: 100vw;
`;

type MobileContentProps = Omit<JobCardContext, "toggleKeyboardShortcuts"> & {
  style?: CSSProperties;
};

export function MobileContent({
  job,
  user,
  style,
  otherJobsExpanded,
  expandOtherJobs,
}: MobileContentProps): React.ReactElement {
  return (
    <MainContentContainer data-testid="job-card-main" style={style}>
      <RoleInfoSection
        job={job}
        user={user}
        otherJobsExpanded={otherJobsExpanded}
        expandOtherJobs={expandOtherJobs}
      />
      <OttasTakeSection job={job} />
      {job.company.workflowStatus !== CompanyWorkflowStatus.CompletedByUser && (
        <CompanyInfoSection job={job} user={user} />
      )}
      <ActionButtonsSection
        job={job}
        user={user}
        otherJobsExpanded={otherJobsExpanded}
        expandOtherJobs={expandOtherJobs}
      />
    </MainContentContainer>
  );
}

function RoleInfoSection({
  job,
  user,
}: Pick<
  MobileContentProps,
  "job" | "user" | "expandOtherJobs" | "otherJobsExpanded"
>): React.ReactElement {
  return (
    <JobCardSection title="Role">
      <SeparateContent>
        <JobRequirements job={job} />
        <JobInvolves job={job} />
        <ApplicationProcess job={job} />
        <SalaryBenchmarks job={job} user={user} />
      </SeparateContent>
    </JobCardSection>
  );
}

function CompanyInfoSection({
  job,
  user,
}: Pick<MobileContentProps, "job" | "user">): React.ReactElement {
  const hasEBContent = useHasEBContent();

  const { meetTheTeamSectionRef, peopleBreakdownRef } = useTrackEBContent(job);

  return (
    <CompanyContainer>
      {job.company.teams.length > 0 && (
        <div ref={meetTheTeamSectionRef}>
          {hasEBContent && <MeetTheTeamSection job={job} />}
        </div>
      )}
      {shouldDisplayInsights(job.company) && (
        <JobCardSection title="Insights">
          <Insights job={job} company={job.company} />
        </JobCardSection>
      )}
      <JobCardSection title="Company">
        <SeparateContent>
          <EmployeeEndorsements company={job.company} user={user} />
          <CompanyFunding company={job.company} />
          <CompanyBenefits job={job} company={job.company} />
          <CompanyValues company={job.company} />
          <CompanyLocation job={job} company={job.company} />
          <VisaDisclaimer job={job} company={job.company} user={user} />
          <CompanyArticles company={job.company} />
          <CompanyAdditionalInfo company={job.company} />
          <PeopleAtCompany company={job.company} />
          {!!job.company.ebStatistics?.peopleBreakdown && (
            <div ref={peopleBreakdownRef}>
              {hasEBContent && <PeopleBreakdown company={job.company} />}
            </div>
          )}
          <SalarySubmission job={job} user={user} />
          <DiversityInitiatives company={job.company} />
        </SeparateContent>
      </JobCardSection>
    </CompanyContainer>
  );
}

function OttasTakeSection({
  job,
}: Pick<MobileContentProps, "job">): React.ReactElement {
  return (
    <JobCardSection
      title="Our take"
      titleColor={palette.brand.yellow}
      tooltipDescription="This is our opinion, written to help you understand more about the company. It's not intended as a statement of fact, but was written to be accurate as possible at the time of publishing."
      TopRightComponent={<StyledWttjSymbol />}
    >
      <OttasTake company={job.company} />
    </JobCardSection>
  );
}

function ActionButtonsSection({
  job,
  user,
  otherJobsExpanded,
  expandOtherJobs,
}: Pick<
  MobileContentProps,
  "job" | "user" | "otherJobsExpanded" | "expandOtherJobs"
>): React.ReactElement {
  return (
    <>
      <JobCardSection>
        <ActionButtonContainer>
          <ShareLink job={job} />
          <ReportAProblem job={job} user={user} />
          <HideCompanyLink company={job.company} user={user} />
          {job.company.workflowStatus !==
            CompanyWorkflowStatus.CompletedByUser && (
            <CompanyOtherJobsButton
              company={job.company}
              user={user}
              currentJobId={job.id}
              expanded={otherJobsExpanded}
              expand={expandOtherJobs}
            />
          )}
        </ActionButtonContainer>
      </JobCardSection>

      {otherJobsExpanded && job.company.liveJobs.length > 1 && (
        <motion.div
          key="job-card-company-jobs"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ opacity: { ease: "easeInOut" } }}
          style={{ width: "100%" }}
        >
          <JobCardSection>
            <CompanyOtherJobsSection
              company={job.company}
              user={user}
              currentJobId={job.id}
            />
          </JobCardSection>
        </motion.div>
      )}
    </>
  );
}
