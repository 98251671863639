import { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css, up, x } from "@xstyled/styled-components";
import { Helmet } from "react-helmet-async";

import { CompaniesList } from "./components/CompaniesList";
import { Preferences } from "./components/Preferences";
import { LoadMoreCompanies } from "./components/LoadMoreCompanies";
import {
  LocationOptions,
  SectorTagOptions,
} from "./components/PreferencesContent";

import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Location, PersonalisedCompaniesDocument } from "@otta/search/schema";
import { Text } from "@otta/design";
import { modularScale } from "@otta/design-tokens";

const Title = styled(Text).attrs({ as: "h1", bold: true })`
  padding-bottom: lg;
  font-size: ${modularScale(2)};

  ${up(
    "tablet",
    css`
      padding-bottom: 3xl;
      font-size: ${modularScale(5)};
    `
  )}
`;

const limit = 16;

export type Filters = {
  companyName: string;
  companySizes: string[];
  sectorTagPreferences: {
    id: string;
    preference: boolean;
  }[];
  locationPreferences: Location[];
};

export function CompaniesIndex(): React.ReactElement {
  const [companyName, setCompanyName] = useState<Filters["companyName"]>("");
  const [locationPreferences, setLocationPreferences] = useState<
    Filters["locationPreferences"]
  >([]);
  const [searchCompanyName, setSearchCompanyName] =
    useState<Filters["companyName"]>("");
  const [companySizes, setCompanySizes] = useState<Filters["companySizes"]>([]);
  const [sectorTagPreferences, setSectorTagPreferences] = useState<
    Filters["sectorTagPreferences"]
  >([]);

  useEffect(() => {
    pushAnalyticsEvent({ eventName: "Viewed Companies List" });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (companyName.length > 0) {
        pushAnalyticsEvent({
          eventName: "User Submitted Company Search",
          searchQuery: companyName,
        });
      }
      setSearchCompanyName(companyName);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [companyName]);

  const handleChangeSearchBar: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(e => {
      setCompanyName(e.target.value);
    }, []);

  const handleClickCompanySize =
    (id: string): React.ChangeEventHandler<HTMLInputElement> =>
    e => {
      if (!e.target.checked && companySizes.includes(id)) {
        const newSizes = companySizes.filter(sizeId => sizeId !== id);
        setCompanySizes(newSizes);
      } else if (!companySizes.includes(id)) {
        pushAnalyticsEvent({
          eventName: "User Added Company Filter",
          filterType: "company-size",
        });
        setCompanySizes([...companySizes, id]);
      }
    };

  const handleSelectSectorTag = ({
    selectedSectorTags: sectorTags,
  }: SectorTagOptions) => {
    if (sectorTags.length > sectorTagPreferences.length) {
      pushAnalyticsEvent({
        eventName: "User Added Company Filter",
        filterType: "sector-tag",
      });
    }
    setSectorTagPreferences(
      sectorTags.map(tag => ({
        id: tag,
        preference: true,
      }))
    );
  };

  const handleSelectLocation = ({
    selectedLocations: locations,
  }: LocationOptions) => {
    if (locations.length > locationPreferences.length) {
      pushAnalyticsEvent({
        eventName: "User Added Company Filter",
        filterType: "location",
      });
    }
    setLocationPreferences(locations);
  };

  const { data, loading, fetchMore, previousData } = useQuery(
    PersonalisedCompaniesDocument,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        limit,
        offset: 0,
        companyName: searchCompanyName,
        companySizes,
        sectorTagPreferences,
        locationPreferences,
      },
    }
  );

  const filters = useMemo(() => {
    return {
      companyName: searchCompanyName,
      companySizes,
      sectorTagPreferences,
      locationPreferences,
    };
  }, [
    companySizes,
    locationPreferences,
    searchCompanyName,
    sectorTagPreferences,
  ]);

  const personalisedCompanies =
    data?.personalisedCompanies ?? previousData?.personalisedCompanies;
  const total = personalisedCompanies?.length ?? 0;
  const isFirstLoading = loading && !personalisedCompanies;

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://app.welcometothejungle.com/companies"
        />
      </Helmet>
      <x.section w="100%" maxW="1000" p="lg" m="0 auto">
        <Title>Discover companies</Title>
        <x.div display="flex" gap="lg">
          <Preferences
            companyName={companyName}
            companySizes={companySizes}
            onChangeSearchBar={handleChangeSearchBar}
            onClickCompanySize={handleClickCompanySize}
            onSelectSectorTag={handleSelectSectorTag}
            onSelectLocation={handleSelectLocation}
          />
          <x.div display="flex" justifyContent="center" w="100%">
            {isFirstLoading ? (
              <Loading />
            ) : (
              <div>
                <CompaniesList
                  companies={personalisedCompanies}
                  filters={filters}
                />
                {total >= limit && (
                  <LoadMoreCompanies
                    onMore={async () => {
                      await fetchMore({
                        variables: {
                          offset: total,
                        },
                      });
                    }}
                  />
                )}
              </div>
            )}
          </x.div>
        </x.div>
      </x.section>
    </>
  );
}
